@charset "UTF-8";
/* left/right padding contentarea */
html {
  background-color: #ffffff;
  min-height: 100%;
  display: flex;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
}

body {
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  width: 100vw;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 90px;
}

a {
  color: hsl(336, 71.8%, 41.7%);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

/* responsive font size */
@media (max-width: 1000px) {
  h1 {
    font-size: 4vw;
  }
}
@media (max-width: 550px) {
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
}
/* header */
header {
  flex: none;
}

.headerimage {
  max-height: 480px;
  overflow: hidden;
  color: #fff;
  position: relative;
}
.headerimage h1 {
  color: inherit;
  padding-bottom: 30px;
}
@media (max-width: 600px) {
  .headerimage h1 {
    padding-bottom: 15px;
  }
}
.headerimage picture {
  height: 100%;
  width: 100%;
  display: block;
  overflow: hidden;
  object-fit: cover;
  object-position: 50% 50%;
}
.headerimage picture img {
  width: 100%;
  vertical-align: bottom;
}
.headerimage .content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.news-date {
  text-transform: uppercase;
}

.contentlist ul {
  list-style-type: none;
  margin: 25px 0 0;
  padding: 25px 0;
  border-top: 1px solid #c5c5c5;
}
.contentlist a {
  color: inherit;
  text-decoration: none;
  display: block;
  position: relative;
  padding-left: 190px;
  min-height: 90px;
}
.contentlist a > * {
  display: block;
}
.contentlist .image {
  position: absolute;
  width: 160px;
  height: 90px;
  top: 0;
  left: 0;
}
.contentlist .image > img {
  vertical-align: bottom;
}
.contentlist .title {
  color: hsl(336, 71.8%, 41.7%);
  font: 700 18px/120% "Montserrat", sans-serif, Arial;
  padding-bottom: 5px;
}
.contentlist .date {
  padding-bottom: 5px;
}

.header__socialmedia {
  color: inherit;
  flex: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
  outline: none;
  cursor: pointer;
  display: flex;
  right: 170px;
}
@media (min-width: 849px) {
  .header__socialmedia {
    display: none;
  }
}

.header__socialmedia + .header__socialmedia {
  right: 135px;
}

.header__socialmedia + .header__socialmedia + .header__socialmedia {
  display: none;
}

main {
  flex: 1 0 auto; /* vertical (column) outfill */
  max-width: 100%;
  padding-top: 80px;
  padding-bottom: 20px;
}

.centercontent {
  width: 100%;
  max-width: 1150px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

main > *,
.headerimage .content > * {
  margin: 0 auto;
  max-width: 1150px;
  padding-left: 20px;
  padding-right: 20px;
}

#mainnav {
  color: #6a6a6a;
  font-family: "Montserrat", sans-serif, Arial;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 90px;
  background-color: hsl(336, 71.8%, 84.7%);
  z-index: 1; /* keep on top */
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}
#mainnav a {
  color: #000;
  text-decoration: none;
}
#mainnav nav {
  padding-top: 24px;
  height: 89px;
  background-color: hsl(336, 71.8%, 84.7%);
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  position: relative;
  z-index: 1; /* keep on top of search box */
}
#mainnav nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 70px 0 0;
}
#mainnav nav > ul {
  white-space: nowrap;
  padding-top: 14px;
}
#mainnav nav > ul > li {
  display: inline-block;
  position: relative;
  padding-bottom: 40px;
}
#mainnav nav > ul > li + li {
  margin-left: 30px;
}
#mainnav nav > ul > li > a {
  text-transform: uppercase;
  font-weight: 700;
  transition: color 0.3s;
}
#mainnav nav > ul > li > a .moreitems {
  display: inline-block;
  font-size: 10px;
  padding-left: 6px;
  padding-top: 4px;
  vertical-align: top;
}
@media (min-width: 849px) {
  #mainnav nav > ul > li.active > a, #mainnav nav > ul > li:hover > a {
    color: #000;
  }
  #mainnav nav > ul > li.active > a > .title, #mainnav nav > ul > li:hover > a > .title {
    border-bottom: 2px solid #000;
  }
}
#mainnav nav > ul > li > ul {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  top: 100%;
  margin-top: -15px;
  left: 50%;
  transform: translate(-50%, -20px);
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
  width: 230px;
  line-height: 125%;
  transition: opacity 0.3s, transform 0.3s;
}
#mainnav nav > ul > li > ul::before, #mainnav nav > ul > li > ul::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
}
#mainnav nav > ul > li > ul::before {
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
  top: -9px;
  left: 50%;
  margin-left: -10px;
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
}
#mainnav nav > ul > li > ul::after {
  top: 0;
  left: 0;
  right: 0;
  height: 15px;
}
#mainnav nav > ul > li > ul > li {
  white-space: normal;
}
#mainnav nav > ul > li > ul > li > a {
  color: hsl(336, 71.8%, 41.7%);
}
#mainnav nav > ul > li > ul > li.active > a, #mainnav nav > ul > li > ul > li:hover > a {
  text-decoration: underline;
}
#mainnav nav > ul > li > ul > li + li {
  margin-top: 15px;
}
#mainnav nav > ul > li:hover > ul {
  opacity: 1;
  transform: translate(-50%, 0);
  pointer-events: auto;
}
#mainnav .logo {
  display: inline-flex;
  text-decoration: none;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  font-size: 22px;
  align-items: center;
  padding-left: 53px;
  padding-right: 15px;
  outline: none;
}
#mainnav .logo img {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 110px;
}
@media (max-width: 320px) {
  #mainnav .logo img {
    width: 90px;
    height: 50px;
  }
}
#mainnav .logo .highlight {
  color: hsl(336, 71.8%, 41.7%);
}
#mainnav .toggle-topsearch {
  position: absolute;
  top: 38px;
  right: 50px;
  width: 20px;
  height: 20px;
  outline: none;
  transition: color 0.3s;
  flex: none;
  color: #000;
}
#mainnav .goto-cart {
  position: absolute;
  top: 38px;
  right: 20px;
  width: 20px;
  height: 20px;
  outline: none;
  transition: color 0.3s;
  flex: none;
  color: #000;
}
#mainnav .mobilenav-toggle {
  color: inherit;
  flex: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
  outline: none;
  cursor: pointer;
  display: flex;
  right: 0px;
  display: none;
}
#mainnav .mobilenav-toggle__hide, html.showmobilemenu #mainnav .mobilenav-toggle__show {
  display: none;
}
html.showmobilemenu #mainnav .mobilenav-toggle__hide {
  display: inline-block;
  padding-right: 3px;
}
#mainnav .mobilenav-toggle:hover {
  color: hsl(336, 71.8%, 41.7%);
}

@media (max-width: 850px) {
  body {
    padding-top: 60px;
  }
  #mainnav {
    height: 60px;
    z-index: 10;
  }
  #mainnav nav {
    height: 59px;
  }
  #mainnav .logo {
    position: absolute;
    top: 9px;
    left: 10px;
  }
  #mainnav .menuitems {
    position: fixed;
    left: -320px;
    top: 66px;
    bottom: 0;
    background-color: #fff;
    padding: 20px;
    white-space: nowrap;
    width: 320px;
    max-width: 100vw;
    border-right: 1px solid #c5c5c5;
    overflow: hidden;
    overflow-y: auto;
    transition: left 0.4s;
  }
  html.showmobilemenu #mainnav .menuitems {
    left: 0;
  }
  #mainnav .menuitems > li {
    display: block;
    margin-left: 0;
    padding-bottom: 15px;
  }
  #mainnav .menuitems > li > ul {
    position: relative;
    margin-top: 15px;
    top: 0;
    left: 0;
    padding: 0;
    transform: none;
    box-shadow: none;
    width: auto;
    display: block;
    height: 0;
    overflow: hidden;
    transition: height 200ms;
    transform: none !important;
    opacity: 1;
    pointer-events: auto;
  }
  #mainnav .menuitems > li > ul::before, #mainnav .menuitems > li > ul::after {
    display: none;
  }
  #mainnav .toggle-topsearch {
    color: inherit;
    flex: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: 50px;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
    outline: none;
    cursor: pointer;
    display: flex;
    right: 85px;
  }
  #mainnav .goto-cart {
    color: inherit;
    flex: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: 50px;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
    outline: none;
    cursor: pointer;
    display: flex;
    right: 45px;
  }
  #mainnav .goto-cart a {
    color: inherit;
  }
  #mainnav .mobilenav-toggle {
    display: flex;
  }
  main {
    padding-top: 30px;
  }
}
@media (max-width: 850px) {
  #mainnav {
    height: 52px;
  }
  #mainnav nav {
    height: 51px;
  }
}
#pathnav {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  list-style-type: none;
  padding: 0 20px 20px;
  margin: 0 auto;
  flex: none;
}
#pathnav li {
  display: inline-block;
}
#pathnav li + li::before {
  display: inline-block;
  content: "›";
  padding: 0 5px;
}
#pathnav a {
  text-decoration: none;
  color: #7c7c7c;
  font-size: 13px;
}
#pathnav a:hover {
  text-decoration: underline;
}

.newsletterform {
  flex: none;
  background-color: hsl(336, 71.8%, 41.7%);
  color: #fff;
}

.newslettersubscription {
  background-color: hsl(336, 71.8%, 41.7%);
  color: #fff;
  flex: none;
}
.newslettersubscription form {
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  position: relative;
}
.newslettersubscription form > * {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 40px;
  border: 0 none;
  max-width: 50%;
  background-color: transparent;
}
.newslettersubscription form .title {
  font: 700 30px/130% "Montserrat", sans-serif, Arial;
  text-transform: uppercase;
  flex: 1 1 50%;
  margin-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.newslettersubscription form input {
  flex: 1 1 50%;
  margin-left: 15px;
  font-size: 16px;
  color: inherit;
  line-height: 20px;
  padding: 10px 80px 10px 15px;
  line-height: 20px;
  border-bottom: 1px solid #fff;
}
.newslettersubscription form button {
  position: absolute;
  right: 35px;
  bottom: 45px;
  flex: none;
  cursor: pointer;
  text-transform: uppercase;
  font: 700 15px/40px "Montserrat", sans-serif, Arial;
  color: inherit;
}
@media (max-width: 1000px) {
  .newslettersubscription form {
    flex-flow: column;
  }
  .newslettersubscription form > * {
    max-width: 100%;
  }
  .newslettersubscription form input {
    margin-top: 10px;
    flex-basis: auto;
    margin-left: 0;
  }
}
@media (max-width: 600px) {
  .newslettersubscription form .title {
    font-size: 5vw;
  }
}

footer {
  flex: none;
  background-color: #4a4a4a;
  color: #fff;
  padding-top: 30px;
}
footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
footer a {
  color: inherit;
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}
footer .columns {
  display: flex;
}
footer .columns > .col {
  flex: 1 1 33%;
  padding-right: 30px;
  max-width: calc(25% + 7px);
  font-size: 15px;
}
footer .columns > .col .col__title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Montserrat", sans-serif, Arial;
  font-weight: 700;
  text-transform: uppercase;
}
footer .columns > .col:last-child {
  max-width: calc(25% - 30px);
  padding-right: 0;
}
footer .columns > .col p {
  margin-bottom: 0;
}
footer .columns > .col .line + .line,
footer .columns > .col p + p {
  padding-top: 10px;
}
@media (max-width: 750px) {
  footer .columns {
    flex-wrap: wrap;
  }
  footer .columns > .col {
    flex-basis: 50%;
    padding-right: 15px;
    max-width: 50%;
  }
  footer .columns > .col:nth-child(2n) {
    padding-left: 15px;
    padding-right: 0;
    max-width: 50%;
  }
  footer .columns > .col:nth-child(3), footer .columns > .col:nth-child(4) {
    margin-top: 15px;
  }
}
@media (max-width: 500px) {
  footer .columns {
    flex-flow: column;
  }
  footer .columns > .col {
    flex-basis: auto;
    padding-right: 15px;
    max-width: 100%;
  }
  footer .columns > .col + .col {
    margin-top: 15px;
    padding-right: 0;
  }
  footer .columns > .col:nth-child(2n) {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}
footer .logo {
  display: inline-flex;
  text-decoration: none;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  font-size: 22px;
  align-items: center;
  height: 42px;
  padding-left: 53px;
  padding-right: 15px;
}
footer .logo:hover {
  text-decoration: none;
}
footer .logo img {
  position: absolute;
  top: 0;
  left: 0;
  height: 42px;
  width: 42px;
}
footer #footernav {
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #fff;
  display: flex;
  justify-content: space-between;
}
footer #footernav li {
  display: inline-block;
}
footer #footernav .footernav li {
  margin-right: 30px;
  font-size: 13px;
}
footer #footernav .socialmedia {
  white-space: nowrap;
}
footer #footernav .socialmedia li {
  margin-left: 15px;
  font-size: 28px;
}
footer #footernav .socialmedia li a {
  transition: opacity 0.3s;
  opacity: 1;
}
footer #footernav .socialmedia li a:hover {
  text-decoration: none;
  opacity: 0.8;
}
footer #footernav .socialmedia .icon {
  display: inline-block;
}
@media (max-width: 600px) {
  footer #footernav {
    display: block;
    padding-bottom: 20px;
  }
  footer #footernav .footernav {
    text-align: center;
  }
  footer #footernav .footernav li {
    margin-right: 15px;
  }
  footer #footernav .footernav li:first-child {
    width: 100%;
    padding-bottom: 5px;
  }
  footer #footernav .socialmedia {
    text-align: center;
    margin-left: 0;
    margin-top: 10px;
  }
}