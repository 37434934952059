*[data-wh-form-group-for=couponcode] {
  margin-top: 5px;
}
*[data-wh-form-group-for=couponcode] .wh-form__fieldline {
  margin-top: 2px;
  align-items: center;
  flex-wrap: nowrap;
}
*[data-wh-form-group-for=couponcode] button {
  padding: 0px 10px;
}
*[data-wh-form-group-for=couponcode] input {
  min-width: 0;
}

.webshop-listcart__productstable {
  width: 100%;
}

.webshop-listcart__linetotalcell,
.webshop-listcart__totalcell {
  text-align: right;
}

.webshop-listcart__total {
  margin-left: 20px;
  font-weight: bold;
}

.webshop-checkout__totalamount {
  margin-top: 30px;
}

.webshop-checkout__ordertotal {
  margin: 20px 0;
  display: block;
}

.webshop-listcart__productamountcell {
  width: 100px;
}

.webshop-checkout__costs {
  margin-left: 30px;
}

.wh-form__label:empty {
  display: none;
}

.webshop-listcart__optionlabel:after {
  content: ": ";
}

.webshop-listcart__deletecell {
  text-align: right;
}

.webshop-listcart__delete {
  cursor: pointer;
}