/* left/right padding contentarea */
button,
.button,
.wh-form__button {
  display: inline-flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: background 0.3s, color 0.3s, border 0.3s;
  padding: 0 40px;
  outline: none;
  color: #fff;
  background: hsl(336, 71.8%, 41.7%);
  border: 1px solid hsl(336, 71.8%, 41.7%);
  height: 50px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
}
button:hover,
.button:hover,
.wh-form__button:hover {
  background: #000;
  color: #fff;
}

.mydialog {
  background: white;
  padding: 20px;
}
.mydialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mydialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.mydialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}