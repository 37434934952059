.webshop-products,
.webshop-categories {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.webshop-categories {
  list-style: none;
}

.webshop-products__item,
.webshop-categories > li {
  width: 32%;
  margin: 0 0 24px;
  padding: 25px 30px;
  border: 1px solid #979797;
  transition: box-shadow 0.3s, border 0.3s;
  display: flex;
  flex-direction: column;
}
.webshop-products__item:hover,
.webshop-categories > li:hover {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
  border-color: transparent;
}
.webshop-products__item--hide,
.webshop-categories > li--hide {
  visibility: hidden;
  pointer-events: none;
}
@media (max-width: 1000px) {
  .webshop-products__item,
  .webshop-categories > li {
    width: 48%;
  }
}
@media (max-width: 700px) {
  .webshop-products__item,
  .webshop-categories > li {
    width: 100%;
  }
}

.webshop-products__metadata {
  margin: 10px 0;
}

.webshop-producst__shortdescription {
  margin: 5px 0 0;
}

.webshop-products__currentprice {
  font-weight: 700;
  font-size: 1.1em;
}

.webshop-products__pricedatadata {
  margin-top: auto;
}

.webshop-products__image {
  max-width: 100%;
  height: auto;
}

.webshop-categories > li {
  display: block;
}