@import url('//fonts.googleapis.com/css?family=Montserrat:400,400i,700');
@import url('//fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i');
@import url('//use.fontawesome.com/releases/v5.7.2/css/all.css');

html
{
  font: 16px 'Open Sans', sans-serif, Arial;
  color: #4a4a4a;
}

h1, h2, h3
{
  line-height: 130%;
  font-family: 'Montserrat', sans-serif, Arial;
  font-weight: 700;
  color: #000;
}
h1
{
  font-size: 40px;
  margin-bottom: 10px;
}
h2
{
  font-size: 22px;
  margin-bottom: 10px;
}
h3
{
  font-size: 18px;
}

p a
{
  color: inherit;
}

p
{
  margin-bottom: 20px;
  line-height: 160%;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/*img
{
  max-width: 100%;
  height: auto;
}
*/
/* lists */
  ul.unordered
, ol.ordered
{
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 0;
  padding-top: 0;
  line-height: 160%;
  list-style-position: inside;
}

.widget, .wh-video
{
  margin-bottom: 20px;
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 25px;
}
.wh-rtd__table
{
  margin-bottom: 25px;
}

.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}
