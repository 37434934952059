/* left/right padding contentarea */
.toggle-topsearch {
  cursor: pointer;
}

.topsearch {
  background-color: hsl(336, 71.8%, 41.7%);
  transition: height 0.4s;
  overflow: hidden;
  height: 0;
  position: relative;
  z-index: 20;
}
html.show-topsearch .topsearch, html.searchpage .topsearch {
  height: 84px;
}
.topsearch > form {
  padding: 20px;
  position: relative;
  max-width: 770px;
  margin: 0 auto;
}
.topsearch > form > * {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 44px;
  border: 0 none;
  font-size: 16px;
}
.topsearch > form input {
  border-radius: 3px;
  width: 100%;
  padding: 12px 45px 12px 15px;
  line-height: 20px;
  background-color: #fff;
}
.topsearch > form button {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: inherit;
  vertical-align: bottom;
  display: inline-block;
  cursor: pointer;
  width: 44px;
  line-height: 32px;
  padding: 0;
  background-color: transparent;
}